AOS.init();

jQuery(document).ready(function ($) {


    if (jQuery("body").hasClass("home")) {
        animateDiv();
        animateDiv2();
       jQuery('html, body').addClass("locked");

        setTimeout(function () {
            jQuery(".curtains__logo").slideDown(1000);
        }, 3500);

        setTimeout(function () {
            jQuery(".curtains__logo").fadeOut(1200);
        }, 7000);
        setTimeout(function () {

            jQuery(".curtains ").delay(800).css("background", "transparent");

            $(".leftcurtain").stop().animate({width: '0'}, 2000);
            $(".rightcurtain").stop().animate({width: '0'}, 2000);

            //jQuery(".curtains__logo").fadeOut('fast');
            // jQuery(".curtains").delay("2200").fadeOut('fast').delay("1000").remove();

            jQuery(".spotlight").stop().fadeOut("slow").delay(500).remove();
            jQuery(".spotlight2").stop().fadeOut("slow").delay(500).remove();

          jQuery('html, body').removeClass("locked");


        }, 9000);
        setTimeout(function () {
            jQuery(".curtains").remove();
        }, 12000);
    }


    jQuery("li.menu-item-has-children a").append("<span class='arrow'><i class='fas fa-chevron-down'></i><i class='fas fa-chevron-up'></i></span>");

    jQuery(".wysiwyg iframe").wrap("<div class='new' data-aos='fade-down'  data-aos-duration='600'></div>");
    jQuery(".wysiwyg figure").wrap("<div class='new' data-aos='fade-down'  data-aos-duration='600'></div>");

    jQuery('#nav-icon3').click(function () {
        if (jQuery("body").hasClass("openmenu")) {
            jQuery(".header__navigation__list").hide();
            jQuery(".header__navigation__nav").slideUp();
            jQuery(".header__helper").hide();
            setTimeout(function () {
                jQuery(".header__navigation__list").show();
            }, 200);
        } else {
            jQuery(".header__navigation__nav").slideDown();
            if (jQuery("body").hasClass("sticky")) {
                jQuery(".header__helper").delay(400).fadeIn(700);
            }
        }
        jQuery(this).toggleClass('open');
        jQuery("body").toggleClass('openmenu');

        if (jQuery(".header").height() >= 134) {
            tempheightTop = jQuery(".header").height() + jQuery("#wpadminbar").height();
            //  tempheightTop = "90px";
        } else {
            tempheightTop = "120px";
        }
        jQuery(".header__navigation").css("top", tempheightTop) + jQuery("#wpadminbar").height();
    });

    jQuery(".toggle-songs").click(function () {
        jQuery(this).parent().toggleClass("active");
    });

    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 70) {
            jQuery('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
            jQuery('.scrolltop').stop(true, true).fadeOut();
        }
    });

    jQuery(".scrolltop").click(function () {
        $("html,body").animate({scrollTop: $(".top").offset().top}, "1000");
        return false
    })

    window.onscroll = function () {
        scrolled();
    };
    var navbar = document.querySelector(".header__content__main");
    var body = document.body;
    var sticky = navbar.offsetTop;
    if (window.pageYOffset >= sticky) {
        body.classList.add("sticky")
    }

    function scrolled() {
        if (window.pageYOffset >= sticky + 100) {
            body.classList.add("sticky")
        } else {
            body.classList.remove("sticky");
        }
    }

    jQuery('.main-slider').slick({
        dots: false,
        infinite: true,
        speed: 1100,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        centerMode: false,
        lazyLoad: 'ondemand',
        swipeToSlide: true,
        cssEase: 'ease-out',
        pauseOnHover: true,
        pauseOnFocus: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });

    jQuery(".youtube,.slick-slide,.btn-play-slider,.btn-pause-slider,.video-box").click(function (e) {
        $('.main-slider').slick('slickPause');
    });
    jQuery(".grid img").click(function (e) {
        window.open(jQuery(this).attr("src"));
    });
/*
    if ((window.location.hash).startsWith("#box")) {
        setTimeout(function () {
            jQuery(window.location.hash).parent().addClass("hovered")
        }, 1100); 

    }*/


    jQuery(".musicbox").mouseenter(function () {
        jQuery(".musicbox").removeClass("hovered");
        jQuery(this).addClass("hovered");
    }).mouseleave(function () {
        jQuery(this).removeClass("hovered");
    });


    jQuery("#edd-field_cfcedd665-wrap").before("<div class='sendgift'><label><input id='sendasgift' type='checkbox' name='sendgift'/>Envoyé en cadeau&nbsp;&nbsp;<i class='fas fa-gift'></i></label></div>");
    jQuery("#edd-purchase-button").after('<input type="button" class="edd-submit dark-gray button" id="fake-edd-purchase-button" name="fake-edd-purchase" value="Confirmé l\'achat">');
    jQuery("#sendasgift").on("change", function (e) {
        if (jQuery(this).is(':checked')) {
            jQuery("#edd-field_cfcedd665-wrap").show();
            jQuery("#edd-field_cfcedd664-wrap").show();
            jQuery("#fake-edd-purchase-button").show();
            jQuery("#edd-purchase-button").hide();

        } else {
            jQuery("#edd-field_cfcedd665-wrap").hide();
            jQuery("#edd-field_cfcedd664-wrap").hide();
            jQuery("#fake-edd-purchase-button").hide();
            jQuery("#edd-purchase-button").show();
        }
    });
    jQuery(document).on("click", "#fake-edd-purchase-button", function (e) {
        if (jQuery("#sendasgift").is(':checked')) {
            jQuery("#edd_error_giftname").remove();
            jQuery("#edd_error_giftnameemail").remove();
            jQuery("#edd_error_giftnameemailvalid").remove();
            var tester = false;
            if (jQuery("#edd-field_cfcedd664-wrap input[type=text]").val() == "") {
                jQuery("#edd-field_cfcedd664-wrap").append('<p class="edd_error" id="edd_error_giftnameemail"><strong>Erreur</strong>: Le courriel du destinaire est manquant</p>');
                tester = true;
            } else {
                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(jQuery("#edd-field_cfcedd664-wrap input[type=text]").val()))) {
                    jQuery("#edd-field_cfcedd664-wrap").append('<p class="edd_error" id="edd_error_giftnameemailvalid"><strong>Erreur</strong>: Le courriel est invalide</p>');
                    tester = true;
                }
            }
            if (jQuery("#edd-field_cfcedd665-wrap input[type=text]").val() == "") {
                jQuery("#edd-field_cfcedd665-wrap").append('<p class="edd_error" id="edd_error_giftname"><strong>Erreur</strong>: Le nom du destinaire est manquant</p>');
                tester = true;
            }

            if (!tester) {
                jQuery("#edd_error_giftnameemail").remove();
                jQuery("#edd_error_giftname").remove();
                jQuery("#edd_error_giftnameemailvalid").remove();
                jQuery("#fake-edd-purchase-button").hide();
                jQuery("#edd-purchase-button").show();
            }

        } else {
            jQuery("#edd_error_giftnameemail").remove();
            jQuery("#edd_error_giftname").remove();
            jQuery("#edd_error_giftnameemailvalid").remove();
            jQuery("#fake-edd-purchase-button").hide();
            jQuery("#edd-purchase-button").show();
        }
    });


    function makeNewPosition() {
        // Get viewport dimensions (remove the dimension of the div)
        var h = $(window).height() - 250;
        var w = $(window).width() - 250;

        var nh = Math.floor(Math.random() * h);
        var nw = Math.floor(Math.random() * w);

        return [nh, nw];

    }

    function animateDiv() {
        var newq = makeNewPosition();
        var oldq = jQuery('.spotlight').offset();
        var speed = calcSpeed([oldq.top, oldq.left], newq);

        jQuery('.spotlight').animate({top: newq[0], left: newq[1]}, speed, function () {
            animateDiv();
        });

    }

    function animateDiv2() {
        var newq2 = makeNewPosition();
        var oldq2 = jQuery('.spotlight2').offset();
        var speed2 = calcSpeed([oldq2.top, oldq2.left], newq2);

        jQuery('.spotlight2').animate({top: newq2[0], left: newq2[1]}, speed2, function () {
            animateDiv2();
        });

    }

    function calcSpeed(prev, next) {
        var x = Math.abs(prev[1] - next[1]);
        var y = Math.abs(prev[0] - next[0]);
        var greatest = x > y ? x : y;
        var speedModifier = 0.5;
        var speed = Math.ceil(greatest / speedModifier);
        return speed;

    }

});
